import React from 'react';
import { useHistory } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'react-i18next';

import { motion } from '@ubisend/framer-motion';

import { CallToAction } from '../Components/index';
import { useBot, useTheme } from '../../../hooks/index';

const Container = styled(motion.div)`
  ${tw`absolute bottom-0 right-0`};
  width: 64px;
  height: 64px;
  padding: 4px;
  margin-bottom: 16px;
  margin-right: 16px;
  & button {
    ${props => `background-color:${props.theme.bot.launcherBackgroundColour};`}
    ${tw`w-full h-full rounded-full shadow border-0 cursor-pointer`};
    padding: 12px;
    > * {
      pointer-events: none;
    }
  }
`;

const LauncherIcon = styled(motion.img)`
  ${tw`max-w-full`};
`;

const variants = {
  hover: {
    scale: 1.1
  },
  tap: {
    scale: 0.9
  }
};

const iconVariants = {
  hover: {
    scale: 1.1,
    transition: {
      type: 'spring',
      stiffness: 300
    }
  }
};

const Launcher = () => {
  const { logo } = useTheme();
  const {
    showCta,
    ctaText,
    hasSeenBanner,
    showBanner,
    onWidgetOpen
  } = useBot();
  const history = useHistory();

  const { t } = useTranslation('converse');

  const handleOpen = () => {
    if (showBanner && !hasSeenBanner) {
      history.push('/window/banner');
    } else {
      history.push('/window/body/messages');
    }

    onWidgetOpen();
  };

  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      {showCta && ctaText && <CallToAction onClick={handleOpen} />}
      <Container>
        <motion.button
          type="button"
          variants={variants}
          whileHover="hover"
          whileTap="tap"
          id="open-widget"
          aria-label={t('launcher_button_description')}
          onClick={handleOpen}>
          <LauncherIcon
            variants={iconVariants}
            src={logo}
            alt={t('launcher_button_description')}
          />
        </motion.button>
      </Container>
    </motion.div>
  );
};

export default Launcher;
