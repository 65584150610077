import React from 'react';
import tw, { styled } from 'twin.macro';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  InnerPanel,
  Placeholder,
  Divider,
  Flex,
  Grid,
  ActionBar,
  Button,
  Span,
  useNotification
} from '@ubisend/pulse-components';

import { FileSourceRouter } from './Routers/index';
import { deleteFileSource } from './api/index';

const Title = styled.h3`
  ${tw`truncate m-0`}
`;

const FooterSection = styled.div`
  ${tw`w-full flex items-center justify-center p-4`}
  margin: -1rem 0;
  &:not(:last-child) {
    ${tw`border-0 border-r border-solid border-grey`}
  }
  &:first-child {
    margin-left: -1rem;
  }
  &:last-child {
    margin-right: -1rem;
  }
`;

const FileSources = () => {
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const query = useQuery('files/sources');
  const mutation = useMutation(deleteFileSource, {
    onSuccess: () => {
      showSuccess('Successfully deleted file storage');
      queryClient.invalidateQueries('files/sources');
    }
  });

  const handleDeleteSource = source => {
    mutation.mutate(source.id);
  };

  return (
    <PageWrapper
      header="File storages"
      subheader="Manage your file storages"
      actions={
        <Button
          variant="primary"
          icon="plus"
          as={Link}
          to={`/files/storages/create`}>
          New Storage
        </Button>
      }>
      <Grid columns={3}>
        {query.isLoading
          ? [0, 1, 2, 3, 4, 5].map(i => (
              <InnerPanel key={i}>
                <Placeholder items={1} />
                <Divider fullWidth />
                <Flex between>
                  {[0, 1].map(i => (
                    <FooterSection key={i}>
                      <Placeholder items={1} subitems={1} />
                    </FooterSection>
                  ))}
                </Flex>
              </InnerPanel>
            ))
          : query.data.data.map(source => (
              <InnerPanel key={source.id}>
                <Flex col tall>
                  <Flex col ySpaceSm grow>
                    <Flex col grow>
                      <Title>{source.name}</Title>
                      <Span light tinyText>
                        Connected to {source.driver.name} on{' '}
                        {dayjs(source.created_at).format('DD/MM/YYYY')}.
                      </Span>
                    </Flex>
                  </Flex>
                  <Divider fullWidth />
                  <ActionBar shiftUp shiftLeft shiftDown shiftRight>
                    <Button
                      disabled={source.built_in}
                      as={Link}
                      to={
                        source.built_in
                          ? '#'
                          : `/files/storages/${source.id}/edit`
                      }
                      variant="inline"
                      icon="pencilAlt"
                      middle
                      pad>
                      Edit
                    </Button>
                    <Button
                      disabled={source.built_in}
                      variant="inline"
                      middle
                      pad
                      onClick={() => handleDeleteSource(source)}
                      colour="danger"
                      icon="trash">
                      Delete
                    </Button>
                  </ActionBar>
                </Flex>
              </InnerPanel>
            ))}
      </Grid>
      <FileSourceRouter />
    </PageWrapper>
  );
};

export default FileSources;
