import tw, { styled } from 'twin.macro';

import { pseudoStyles } from '@ubisend/pulse-components';

const HeaderButton = styled.button`
  ${tw`p-0 border-none bg-transparent relative cursor-pointer rounded flex`}
  svg {
    ${tw`opacity-75`};
  }
  &:hover svg {
    ${tw`opacity-100`};
  }
  > * {
    pointer-events: none;
  }
  ${pseudoStyles}
`;

HeaderButton.defaultProps = {
  colour: 'white'
};

export default HeaderButton;
