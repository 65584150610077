import React, { useState, useMemo, useRef } from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import chroma from 'chroma-js';

import { useMutation } from '@ubisend/pulse-hooks';
import { motion, AnimatePresence } from '@ubisend/framer-motion';
import Icon from '@ubisend/pulse-icons';
import { useTheme } from '@ubisend/pulse-volt';

import { useCanvas, useDragPlaceholder, useBuilder } from '../../hooks/index';
import { nodeType } from '../../PropTypes/index';
import nodeTypes from './Types/index';
import blockTypes from '../Blocks/Types/index';
import {
  updateStepPosition as updateStepPositionApi,
  updateStepTransitionPosition as updateStepTransitionPositionApi,
  updateStepTriggerPosition as updateStepTriggerPositionApi
} from '../../api/index';
import { NODE_WIDTH } from '../Canvas/renders';

const Container = styled(motion.div)`
  ${tw`relative`}
`;

const DragPlaceholder = styled(motion.div)`
  ${tw`absolute rounded`}
  border: 2px solid ${chroma('#000').alpha(0.5)};
`;

const Overlay = styled.div`
  ${tw`flex items-center justify-center rounded z-30`}
  color: ${props => (props.borderColour ? props.borderColour : '#007E2E')};
  text-shadow: 0 0 3px #ff0000;
  pointer-events: none;
  background-color: ${props =>
    props.colour
      ? chroma(props.colour).alpha(props.valid ? 0.5 : 0.05)
      : 'none'};
  border: 2px ${props => props.borderColour ?? '#007E2E'};
  border-style: ${props => props.borderStyle ?? 'dashed'};
  width: 100%;
  height: 100%;
  position: absolute;
`;

const categoryNames = {
  step: 'Step Actions',
  transition: 'Transition Actions',
  trigger: 'Transition Actions',
  validation: 'Transition Actions'
};

const useUpdateNodePosition = ({
  node,
  setDragging,
  mouseOffset,
  setMouseOffset
}) => {
  const { mousePositionX, mousePositionY } = useCanvas();
  const { conversation, dispatch } = useBuilder();

  const updateStepPosition = useMutation(updateStepPositionApi);
  const updateStepTransitionPosition = useMutation(
    updateStepTransitionPositionApi
  );
  const updateStepTriggerPosition = useMutation(updateStepTriggerPositionApi);

  const avoidXZeroBug = x => {
    return x === 0 ? 1 : x;
  };

  const handleUpdateStepPosition = details => {
    details.x = avoidXZeroBug(details.x);
    dispatch({
      type: 'UPDATE_STEP',
      stepId: node.id,
      stepDetails: details
    });
    updateStepPosition.mutate({
      conversationId: conversation.id,
      stepId: node.id,
      position: { x: details.x, y: details.y }
    });
  };

  const handleUpdateTransitionPosition = details => {
    details.x = avoidXZeroBug(details.x);
    dispatch({
      type: 'UPDATE_TRANSITION',
      stepId: node.meta.stepId,
      transitionId: node.id,
      transitionDetails: details,
      transitionType: node.type
    });

    updateStepTransitionPosition.mutate({
      stepId: node.meta.stepId,
      transitionId: node.id,
      position: { x: details.x, y: details.y }
    });
  };

  const handleUpdateTriggerPosition = details => {
    details.x = avoidXZeroBug(details.x);
    dispatch({
      type: 'UPDATE_TRIGGER',
      stepId: node.meta.stepId,
      triggerId: node.id,
      triggerDetails: details
    });
    updateStepTriggerPosition.mutate({
      stepId: node.meta.stepId,
      triggerId: node.id,
      position: { x: details.x, y: details.y }
    });
  };

  const handleEndDrag = () => {
    setDragging(false);

    const handlers = {
      step: handleUpdateStepPosition,
      transition: handleUpdateTransitionPosition,
      validation: handleUpdateTransitionPosition,
      trigger: handleUpdateTriggerPosition
    };

    const handler = handlers[node.type];

    handler({
      ...node.base,
      x: Math.round(mousePositionX.get() + mouseOffset.x),
      y: Math.round(mousePositionY.get() + mouseOffset.y)
    });
    setMouseOffset({
      x: 0,
      y: 0
    });
  };

  return handleEndDrag;
};

const Node = ({ node, x, y }) => {
  const ref = useRef(null);
  const [addBlock, setAddBlock] = useState(null);
  const [editBlock, setEditBlock] = useState(null);
  const [hoverStyle, setHoverStyle] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [mouseOffset, setMouseOffset] = useState({ x: 0, y: 0 });
  const theme = useTheme();

  const {
    hoveredBlock,
    panning,
    nodeSizes,
    mousePositionX,
    mousePositionY
  } = useCanvas();

  if (node && node.height && nodeSizes.length) {
    node.height = nodeSizes.filter(
      filteredNode => filteredNode.id === node.id
    )[0].height;
  }

  const { dragging, setDragging, placeholderStyle } = useDragPlaceholder(
    node,
    mouseOffset
  );
  const handleEndDrag = useUpdateNodePosition({
    node,
    setDragging,
    mouseOffset,
    setMouseOffset
  });

  /**
   * https://stackoverflow.com/questions/5429827/how-can-i-prevent-text-element-selection-with-cursor-drag
   */
  const handleStartDrag = event => {
    event.stopPropagation();
    event.preventDefault();
    event.cancelBubble = true;
    event.returnValue = false;

    if (panning || dragging) {
      return false;
    }
    setMouseOffset({
      x: x - mousePositionX.get(),
      y: y - mousePositionY.get()
    });
    setDragging(true);

    return false;
  };

  const { Node: NodeComponent, ...nodeType } = useMemo(() => {
    return nodeTypes[node.style];
  }, [node.style]);

  const { AddBlock } = useMemo(() => {
    if (!addBlock) {
      return {};
    }

    const types = { ...nodeTypes, ...blockTypes };

    return types[addBlock.id];
  }, [addBlock]);

  const { EditBlock } = useMemo(() => {
    if (!editBlock) {
      return {};
    }

    const types = { ...nodeTypes, ...blockTypes };

    return types[editBlock.type];
  }, [editBlock]);

  const [, drop] = useDrop({
    accept: 'BLOCK',
    drop: item => {
      if (nodeType.canDrop(item)) {
        setAddBlock(item);
      }
    },
    canDrop: item => nodeType.canDrop(item),
    collect: monitor => {
      const hovering = monitor.isOver();
      if (!hovering) {
        return setHoverStyle(null);
      }
      setHoverStyle('#007E2E');
    }
  });

  const handleEditBlock = block => {
    setEditBlock(block);
  };

  drop(ref);

  const blockIsValid =
    hoveredBlock && categoryNames[node.type] === hoveredBlock.name;

  const isHoveredSteps = hoveredBlock && hoveredBlock.name !== 'Steps';

  const size = nodeSizes.find(
    info => info.id === node.id && info.type === node.type
  );

  const style = {
    position: 'absolute',
    width: NODE_WIDTH,
    height: size?.height,
    x: x - NODE_WIDTH / 2,
    y: y - size?.height / 2,
    zIndex: isDropdownOpen ? 15 : 10
  };

  return (
    <>
      <AnimatePresence>
        {AddBlock && (
          <AddBlock handleClose={() => setAddBlock(null)} node={node} />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {EditBlock && (
          <EditBlock
            handleClose={() => setEditBlock(null)}
            node={node}
            block={editBlock}
          />
        )}
      </AnimatePresence>
      <Container ref={ref} style={style}>
        {!blockIsValid && hoveredBlock && isHoveredSteps && (
          <Overlay
            colour={theme.danger}
            valid={false}
            borderColour={theme.danger}>
            <Icon solid type="xCircle" />
          </Overlay>
        )}
        {blockIsValid && (
          <Overlay
            transition={{ duration: 0.2 }}
            colour="#F1F8F3"
            borderStyle={hoverStyle ? 'solid' : 'dashed'}
            valid={true}>
            <Icon solid type="plus" />
          </Overlay>
        )}
        <div
          role="button"
          tabIndex="0"
          onMouseDown={handleStartDrag}
          onMouseUp={handleEndDrag}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'between',
            height: '100%'
          }}>
          <NodeComponent
            node={node}
            handleEditBlock={handleEditBlock}
            setIsDropdownOpen={setIsDropdownOpen}
          />
        </div>
      </Container>
      {dragging && (
        <DragPlaceholder style={placeholderStyle} onMouseUp={handleEndDrag} />
      )}
    </>
  );
};

Node.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  node: nodeType.isRequired
};

export { Overlay };
export default Node;
