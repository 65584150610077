import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import { Flex } from '@ubisend/pulse-components';

import {
  Header,
  Name,
  CloseWidgetButton,
  CogButton,
  TicketButton,
  NotificationButton
} from './Header/index';
import ConnectionStatus from './ConnectionStatus';
import { TicketFilter, BotLogo } from '../../../Components/index';
import { useBot, useNotifications } from '../../../hooks/index';

const getSize = (name = '') => {
  if (name.length < 8) {
    return tw`text-xl`;
  }

  if (name.length < 14) {
    return tw`text-lg`;
  }

  return tw`text-base`;
};

const WindowHeader = () => {
  const { t } = useTranslation(['bots', 'converse']);

  const {
    logo,
    name,
    showLogo,
    showName,
    logoLink,
    showClose,
    onWidgetClose,
    hasActiveSettings
  } = useBot();

  const location = useLocation();

  const history = useHistory();

  const { notifications } = useNotifications();

  const closeWidget = () => {
    history.push('/', { closed: true });
    onWidgetClose();
  };

  const toggleNotifications = () => {
    if (location.pathname === '/window/body/notifications') {
      history.push('/window/body/messages');
    } else {
      history.push('/window/body/notifications');
    }
  };

  const toggleTickets = () => {
    if (location.pathname === '/window/body/tickets') {
      history.push('/window/body/messages');
    } else {
      history.push('/window/body/tickets');
    }
  };

  const toggleSettings = () => {
    if (location.pathname === '/window/body/settings') {
      history.push('/window/body/messages');
    } else {
      history.push('/window/body/settings');
    }
  };

  return (
    <>
      <Header style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Flex center>
          <BotLogo
            showLogo={showLogo}
            logoLink={logoLink}
            logo={logo}
            style={{
              marginRight: '2rem',
              maxWidth: '100px',
              maxHeight: '40px'
            }}
          />
          <div>{showName && <Name size={getSize(name)}>{name}</Name>}</div>
        </Flex>
        <Flex col bottom ySpaceSm>
          <Flex right center noGrow xSpaceSm>
            <TicketFilter>
              <TicketButton
                aria-label={t('ticket_button_label', { ns: 'bots' })}
                onClick={toggleTickets}
              />
            </TicketFilter>
            {notifications.length !== 0 && (
              <NotificationButton
                aria-label={t('notification_button_label', { ns: 'bots' })}
                inverse={false}
                scale={1}
                onClick={toggleNotifications}
              />
            )}
            {hasActiveSettings && (
              <CogButton
                onClick={toggleSettings}
                aria-label={t('settings_header', { ns: 'bots' })}
              />
            )}
            {showClose && (
              <CloseWidgetButton
                aria-label={t('close_widget_button_label', { ns: 'converse' })}
                onClick={closeWidget}
              />
            )}
          </Flex>
          <ConnectionStatus />
        </Flex>
      </Header>
    </>
  );
};

WindowHeader.propTypes = {
  as: PropTypes.element,
  inverse: PropTypes.bool,
  scale: PropTypes.number
};

export default WindowHeader;
