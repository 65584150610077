import React from 'react';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'react-i18next';

import { Span } from '@ubisend/pulse-components';

import { useBot } from '../../../hooks/index';

const Image = styled.img`
  ${tw`h-4`};
  margin-left: 0.3rem;
  margin-bottom: 0.1rem;
`;

const Link = styled.a`
  ${tw`flex items-center text-black no-underline `};
`;

const logo =
  'https://s3.eu-west-2.amazonaws.com/ubisend.website/assets/static/images/logo.svg';

const WidgetFooter = () => {
  const { showPoweredByUbisend, footerImageUrl, footerUrl } = useBot();
  const { t } = useTranslation('bots');

  if (!showPoweredByUbisend) {
    return null;
  }

  const FooterImage = () => (
    <Image
      src={footerImageUrl ?? logo}
      alt={t('bot footer logo')}
      aria-label={t('widget footer image')}
    />
  );

  if (footerImageUrl && !footerUrl) {
    return <FooterImage />;
  }

  return (
    <Link
      rel="nofollow noopener noreferrer"
      target="_blank"
      aria-label={t('widget footer')}
      href={footerUrl || 'https://www.ubisend.com/powered-by-ubisend'}>
      {!footerImageUrl && <Span>{t('powered by')}</Span>}
      <FooterImage />
    </Link>
  );
};

export default WidgetFooter;
export { logo };
