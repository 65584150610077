import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import { useResubscribe } from '../../../../hooks';

const StyledEventMessage = styled.div`
  ${tw`text-xs text-black text-center mb-2 italic whitespace-pre-wrap`}
  overflow-wrap: break-word;
`;

const Event = ({ content }) => {
  const { resubscribe } = useResubscribe();

  if (
    content.type === 'ACTION_EVENT' &&
    content.action === 'End current session'
  ) {
    setTimeout(() => resubscribe(), 5000);
  }
  return (
    <StyledEventMessage data-testid="event-message">
      {content.text}
    </StyledEventMessage>
  );
};

Event.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string,
    action: PropTypes.string,
    type: PropTypes.string
  })
};

export default Event;
