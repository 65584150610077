import React, { useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'react-i18next';

import { useAutoTextArea } from '@ubisend/pulse-component-hooks';

import { useComposer, useDelivery, useBot } from '../../../../../hooks/index';
import { isValidMessage } from '../../../../../utilities/index';
import SendButton from '../SendButton';
import {
  MAX_MESSAGE_LENGTH,
  MIN_MESSAGE_LIMIT
} from '../../../../../constants';

const Container = styled.div`
  ${tw`relative bg-white text-gray-500 flex h-auto items-center flex items-center`};
  padding: 0.8rem 0;
`;

const TextArea = styled.textarea`
  ${tw`w-full font-thin text-base py-4 px-4 pt-5 h-16 focus:outline-none resize-none`};
  border: none;
  box-sizing: border-box;
`;

const isValid = message => {
  return isValidMessage(message, {
    min: MIN_MESSAGE_LIMIT,
    max: MAX_MESSAGE_LENGTH
  });
};

const FeedbackComposer = () => {
  const { reply, setReply } = useComposer();
  const { autofocusComposer } = useBot();

  const ref = useAutoTextArea(reply, {
    lineHeight: 25,
    initialTextAreaHeight: 38
  });

  useEffect(() => {
    // Only autofocus if the user has previously interacted with the bot.
    if (autofocusComposer) {
      ref.current.focus();
    }
  });

  const { t } = useTranslation('bots');

  const { sendMessage } = useDelivery();

  const handleOnChange = event => {
    const value = event.target.value;

    if (value.length >= MAX_MESSAGE_LENGTH) {
      return;
    }

    setReply(value);
  };

  const handleNewReply = () => {
    const validatedMessage = isValid(reply);

    if (!validatedMessage) {
      return;
    }

    ref.current.focus();
    sendMessage(validatedMessage);
    setReply('');
  };

  return (
    <Container>
      <TextArea
        ref={ref}
        value={reply}
        onChange={handleOnChange}
        placeholder={t('feedback_composer_label')}
        aria-label={t('feedback_composer_label')}
      />
      <SendButton
        aria-label={t('send_button_label')}
        onClick={handleNewReply}
      />
    </Container>
  );
};

export default FeedbackComposer;
export { MAX_MESSAGE_LENGTH, MIN_MESSAGE_LIMIT, isValid };
