import React, { useEffect, useRef } from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Flex,
  Span,
  useTheme,
  Button,
  pseudoStyles
} from '@ubisend/pulse-components';
import { AnimatePresence } from '@ubisend/framer-motion';
import Icon from '@ubisend/pulse-icons';

import { NOTIFICATION_TYPES } from '../../../../reducers/useNotificationReducer';
import BurgerButton from './BurgerButton';
import UploadButton from './UploadButton';
import SendButton from './SendButton';
import { useBot, useDelayedBot } from '../../../../hooks';

const Container = styled.div`
  ${tw`relative bg-white text-gray-500 flex flex-col items-center flex-shrink-0`};
`;

const Input = styled.input`
  ${tw`w-full font-thin text-base h-16 py-2 pr-4 pl-3 focus:outline-none rounded`};
  box-sizing: border-box;
  border: 1px solid transparent;
  ${pseudoStyles}
  &:disabled {
    ${tw`opacity-100 bg-white text-black cursor-not-allowed`}
  }
`;

const notificationAnimation = {
  initial: { opacity: 0, y: 50 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 600,
      damping: 50
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2
    }
  }
};

const Notification = ({ children, type, handleClose }) => {
  const theme = useTheme();

  const types = {
    [NOTIFICATION_TYPES.WARNING]: {
      icon: 'exclamation',
      colour: theme.warning
    },
    [NOTIFICATION_TYPES.ERROR]: {
      icon: 'exclamation',
      colour: theme.danger
    }
  };

  return (
    <Flex pad center borderBottom relative fat {...notificationAnimation}>
      <Button
        onClick={handleClose}
        variant="inline"
        icon="x"
        style={{ position: 'absolute', top: 0, right: 0 }}
      />
      <Flex xSpaceSm mr center>
        <Icon
          type={types[type].icon}
          colour={types[type].colour}
          size="1.5rem"
          width="1.5rem"
          height="1.5rem"
        />
        <Span tinyText>{children}</Span>
      </Flex>
    </Flex>
  );
};
Notification.propTypes = {
  type: PropTypes.oneOf(Object.keys(NOTIFICATION_TYPES)).isRequired,
  handleClose: PropTypes.func.isRequired
};

const BaseComposer = ({
  handleSubmit,
  showBurgerIcon = false,
  toggleBurgerMenu,
  showFileUpload = false,
  disableFileUpload = false,
  handleFileSelect,
  notification,
  handleNotificationClose,
  disabled = false,
  inputType = 'text',
  value,
  ...rest
}) => {
  const { t } = useTranslation('bots');
  const { showBurgerMenu } = useDelayedBot();
  const { autofocusComposer } = useBot();
  const inputRef = useRef();

  useEffect(() => {
    if (autofocusComposer) {
      inputRef.current.focus();
    }
  });

  const handleKeyDown = event => {
    // Submit on enter.
    if (event.keyCode !== 13) {
      return;
    }

    handleSubmit();
  };

  return (
    <Container>
      <AnimatePresence>
        {notification && notification.hasNotification && (
          <Notification
            type={notification.notification.type}
            handleClose={handleNotificationClose}>
            {notification.notification.message}
          </Notification>
        )}
      </AnimatePresence>
      <Flex
        xSpaceSm
        middle
        fat
        center
        style={{ padding: '0.25rem 0.5rem 0.25rem 1.25rem' }}>
        {showBurgerIcon && (
          <BurgerButton
            aria-label={t('burger_button_label')}
            onClick={toggleBurgerMenu}
            isOpen={showBurgerMenu}
          />
        )}
        <Input
          ref={inputRef}
          disabled={disabled}
          type={inputType}
          onKeyDown={handleKeyDown}
          value={value}
          {...rest}
        />
        {showFileUpload && (
          <UploadButton
            aria-label={t('upload_button_label')}
            onChange={handleFileSelect}
            disabled={disableFileUpload}
          />
        )}
        <Flex>
          <SendButton
            aria-label={t('send_button_label')}
            disabled={value.length === 0 || disabled ? true : false}
            onClick={handleSubmit}
          />
        </Flex>
      </Flex>
    </Container>
  );
};

BaseComposer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  showBurgerIcon: PropTypes.bool,
  toggleBurgerMenu: PropTypes.func,
  showFileUpload: PropTypes.bool,
  disableFileUpload: PropTypes.bool,
  handleFileSelect: PropTypes.func,
  notification: PropTypes.shape({
    hasNotification: PropTypes.bool.isRequired,
    notification: PropTypes.shape({
      type: Notification.propTypes.type,
      message: PropTypes.string
    }).isRequired
  }),
  handleNotificationClose: PropTypes.func,
  disabled: PropTypes.bool,
  inputType: PropTypes.oneOf(['text', 'number']),
  value: PropTypes.string
};
BaseComposer.Container = Container;
BaseComposer.Input = Input;
BaseComposer.Notification = Notification;

export default BaseComposer;
