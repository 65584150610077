import React, { useState, useRef } from 'react';
import dayjs from 'dayjs';
import { useDrop } from 'react-dnd';
import tw, { styled } from 'twin.macro';
import chroma from 'chroma-js';

import {
  useQuery,
  useMutation,
  useCurrentTimeZone
} from '@ubisend/pulse-hooks';
import { motion, AnimateSharedLayout } from '@ubisend/framer-motion';
import {
  PageWrapper,
  Panel,
  Button,
  StickyFooter,
  PanelSlider,
  useNotification,
  Flex,
  EmptyStatePlaceholder,
  Grid,
  Heading2,
  FilterMenu,
  useFilterReducer,
  Label,
  DateTimePicker,
  Checkbox,
  Tooltip
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { AllMetrics, Metric } from './Components/index';
import types from '../../Components/Types/index';
import { updateMetrics } from './api/index';
import { MetricRouter } from './Routers/index';

const Placeholder = styled(motion.div)`
  ${tw`rounded-sm`}
  width: 100%;
  height: 100%;
  min-height: 8rem;
  grid-column: span ${props => props.columns} / auto;
  grid-row: auto / span ${props => props.rows};
  border: 1px solid ${props => chroma(props.theme.primary).alpha(0.5)};
  background: ${props => chroma(props.theme.primary).alpha(0.25)};
`;
const Dashboard = () => {
  const timezone = useCurrentTimeZone();
  const filters = useFilterReducer({
    id: 'dashboard',
    initialFilters: {
      start: new Date(dayjs().subtract(1, 'weeks')),
      end: new Date(dayjs().add('1', 'hours')),
      compare: false,
      timezone: timezone
    }
  });

  const [compare, setCompare] = useState(true);
  const [metricKeys, setMetricKeys] = useState();
  const [editing, setEditing] = useState(false);
  const [addingNew, setAddingNew] = useState(false);
  const [dragPoint, setDragPoint] = useState(null);
  const [placeholderIndex, setPlaceholderIndex] = useState(null);
  const [draggedMetric, setDraggedMetric] = useState(null);

  const { showSuccess } = useNotification();

  const ref = useRef(null);
  const oldKeys = useRef();

  useQuery('metrics', {
    onSuccess: ({ data }) => {
      setMetricKeys(data);
    }
  });
  const { mutate } = useMutation(updateMetrics, {
    onSuccess: () => {
      setEditing(false);
      showSuccess('Successfully updated your metrics.');
    }
  });

  const toggleAdding = () => setAddingNew(adding => !adding);

  const startEditing = () => {
    oldKeys.current = metricKeys;
    setEditing(true);
  };

  const cancelEditing = () => {
    setMetricKeys(oldKeys.current);
    setEditing(false);
  };

  const handleNewStart = ([newStart]) => {
    filters.updateFilters({
      start: newStart
    });
  };

  const handleNewEnd = ([newEnd]) => {
    filters.updateFilters({
      end: newEnd
    });
  };

  const handleNewCompare = () => {
    setCompare(!compare);
    filters.updateFilters({
      compare: compare
    });
  };

  const handleDelete = id => {
    setMetricKeys(keys => keys.filter(key => key.id !== id));
  };

  const handleNewMetric = item => setMetricKeys(keys => keys.concat(item));

  const handleSubmit = () => {
    mutate(metricKeys.map(({ id }) => id));
  };

  const handleDrop = () => {
    const fromIndex = metricKeys.findIndex(key => key.id === draggedMetric);
    const mutableMetricKeys = [...metricKeys];
    mutableMetricKeys.splice(
      placeholderIndex,
      0,
      mutableMetricKeys.splice(fromIndex, 1)[0]
    );

    setPlaceholderIndex(null);
    setDraggedMetric(null);
    setMetricKeys(mutableMetricKeys);
  };

  const [, drop] = useDrop({
    accept: 'METRIC',
    drop(item) {
      if (!ref.current) {
        return;
      }

      handleDrop(item.id);
    }
  });

  const handleHover = (ref, mousePosition, fromId, toId) => {
    setDraggedMetric(fromId);

    const index = metricKeys.findIndex(key => key.id === toId);
    if (toId === fromId) {
      return setPlaceholderIndex(index);
    }

    const rect = ref.current.getBoundingClientRect();

    const x = mousePosition.x - rect.left;

    const normalisedX = x / rect.width;

    // If on the right hand side of a metric, set the placeholder to show after
    setPlaceholderIndex(normalisedX > dragPoint.x ? index + 1 : index);
  };

  const handleMouseDown = event => {
    // If we aren't editing metrics or d
    if (!editing || dragPoint) {
      return;
    }
    // Get the bounding rectangle of target
    const rect = event.target.getBoundingClientRect();

    // Mouse position
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const normalisedX = x / rect.width;
    const normalisedY = y / rect.height;
    setDragPoint({ x: normalisedX, y: normalisedY });
  };

  drop(ref);

  return (
    <React.Fragment>
      <MetricRouter />
      <PageWrapper
        header="Dashboard"
        subheader="Your chatbot's performance"
        actions={
          <Flex xSpace>
            <FilterMenu
              position={FilterMenu.POSITIONS.LEFT}
              buttonProps={{
                disabled: !(metricKeys && metricKeys.length !== 0)
              }}
              {...filters.props}>
              <Flex col style={{ width: '20rem' }}>
                <Flex col fat mb>
                  <Label htmlFor="start">Start</Label>
                  <DateTimePicker
                    id="start"
                    date={filters.filters.start}
                    onChange={handleNewStart}
                  />
                </Flex>
                <Flex col fat>
                  <Label htmlFor="end"> End</Label>
                  <DateTimePicker
                    id="end"
                    date={filters.filters.end}
                    onChange={handleNewEnd}
                  />
                </Flex>
                <Flex fat center mt>
                  <Checkbox
                    checked={filters.filters.compare}
                    onChange={handleNewCompare}
                    label="Compare with previous period"
                  />
                  <Tooltip
                    position={Tooltip.POSITIONS.BOTTOM}
                    tooltip={
                      <Tooltip.Content>
                        When ticked, the metric value for the previous period
                        will appear in brackets.
                      </Tooltip.Content>
                    }>
                    <Tooltip.Icon>?</Tooltip.Icon>
                  </Tooltip>
                </Flex>
              </Flex>
            </FilterMenu>
            {metricKeys && metricKeys.length !== 0 && (
              <PermissionFilter can="edit metrics">
                <Button
                  variant="primary"
                  {...(editing && { colour: 'danger' })}
                  icon={editing ? null : 'pencilAlt'}
                  onClick={editing ? cancelEditing : startEditing}>
                  {editing ? 'Cancel' : 'Edit dashboard'}
                </Button>
              </PermissionFilter>
            )}
          </Flex>
        }>
        {!metricKeys && (
          <Grid columns={3}>
            <Panel loading loadingItems={1} />
            <Panel loading loadingItems={1} />
            <Panel loading loadingItems={1} />
            <Panel loading loadingItems={1} />
            <Panel loading loadingItems={1} />
            <Panel loading loadingItems={1} />
          </Grid>
        )}
        {metricKeys && metricKeys.length === 0 && (
          <Panel>
            <Flex col middle>
              <EmptyStatePlaceholder
                type="dashboard"
                heading="Select a metric"
                text="Monitor your automation's performance by building a custom dashboard."
                helpLink="/docs/2151317744/2153545787"
                helpText="Learn more about metrics."
                actions={
                  <PermissionFilter can="edit metrics">
                    <Button
                      variant={editing ? 'secondary' : 'primary'}
                      {...(editing && { colour: 'danger' })}
                      icon={editing ? null : 'plus'}
                      onClick={editing ? cancelEditing : startEditing}>
                      {editing ? 'Cancel' : 'Add metric'}
                    </Button>
                  </PermissionFilter>
                }
              />
            </Flex>
          </Panel>
        )}
        {metricKeys && metricKeys.length !== 0 && (
          <Flex col ySpace>
            <Grid columns={6} ref={ref}>
              <AnimateSharedLayout>
                {metricKeys.map((metric, i) => {
                  return (
                    <React.Fragment key={i}>
                      {editing && i === placeholderIndex && (
                        <Placeholder
                          layoutId="drag-placeholder"
                          columns={
                            types[
                              metricKeys.find(({ id }) => draggedMetric === id)
                                .type
                            ].columns
                          }
                          rows={
                            types[
                              metricKeys.find(({ id }) => draggedMetric === id)
                                .type
                            ].rows
                          }
                        />
                      )}
                      {draggedMetric !== metric.id && (
                        <Metric
                          key={metric.id}
                          metric={metric}
                          filters={filters.form}
                          editing={editing}
                          handleHover={handleHover}
                          handleDelete={handleDelete}
                          handleMouseDown={handleMouseDown}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </AnimateSharedLayout>
            </Grid>
          </Flex>
        )}
      </PageWrapper>
      {editing && (
        <StickyFooter>
          <Flex center between>
            <Heading2>Editing your dashboard</Heading2>
            <Flex xSpace>
              <Button
                variant="secondary"
                colour="danger"
                onClick={cancelEditing}>
                Cancel
              </Button>
              <Button variant="secondary" onClick={toggleAdding}>
                View metrics
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Save
              </Button>
            </Flex>
          </Flex>
        </StickyFooter>
      )}
      {addingNew && (
        <PanelSlider width="40%" handleHide={toggleAdding} header="All metrics">
          <AllMetrics
            handleMetricClick={handleNewMetric}
            activeMetrics={metricKeys}
          />
        </PanelSlider>
      )}
    </React.Fragment>
  );
};

export default Dashboard;
